import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import ContentWrapper from '../components/contentwrapper/ContentWrapper'
import { Link, useNavigate } from 'react-router-dom';
import { fetchAllDashboardDataAPI, fetchFilterAgentDataAPI, fetchFilterOrderDataAPI } from '../utils/api';
import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton';
import { FaRegEye } from 'react-icons/fa';
import { Badge, Button, Col, Form, Row, Table } from 'react-bootstrap';
import moment from 'moment';
import { numberFormat } from '../components/numberFormat';
import { FiRefreshCcw } from "react-icons/fi";
import { CiExport } from 'react-icons/ci';
import CsvDownloader from 'react-csv-downloader';

const AllOrder = () => {
    const [order, setOrder] = useState([]);
    const [isLoading, setIsloading] = useState(false);
    const accountid = localStorage.getItem("accountdata");
    const navigate = useNavigate();

    const [currentPage, setCurrentPage] = useState(1);
    const recordPerPage = 50;
    const lastIndex = currentPage * recordPerPage;
    const firstIndex = lastIndex - recordPerPage;
    const records = order.slice(firstIndex, lastIndex);
    const npage = Math.ceil(order.length / recordPerPage)
    const numbers = [...Array(npage + 1).keys()].slice(1)
    const [filtervalues, setFilterValue] = useState();
    const [filtervalidated, setFilterValidated] = useState(false);

    useEffect(() => {
        if (accountid) {
            navigate('/all-orders');
            getAllCreatedOrder();
        }
        else {
            navigate("/");
        }
    }, [accountid]);

    const getAllCreatedOrder = () => {
        setIsloading(true);
        fetchAllDashboardDataAPI('account-get-all-orders-data').then((res) => {
            if (res.data.status === 1) {
                setIsloading(false);
                setOrder(...[res.data.order]);
            }
            else {
                setIsloading(false);
                toast.warning("Check your connection")
            }
        })
    }
    const columns = [
        {
            id: 'p_order_number',
            displayName: 'Order No.'
        },
        {
            id: 'delivery_date',
            displayName: 'Delivery Date'
        },
        {
            id: 'order_gst_no',
            displayName: 'GST No.'
        },
        {
            id: 'order_name',
            displayName: 'Buyer Name'
        },
        {
            id: 'p_name',
            displayName: 'Product Name'
        },
        {
            id: 'p_qty',
            displayName: 'QTY'
        },
        {
            id: 'p_selling_price',
            displayName: 'Sell Price'
        },
        {
            id: 'order_state',
            displayName: 'Buyer State'
        },
        {
            id: 'p_hsn_code',
            displayName: 'HSN Code'
        },
        {
            id: 'p_tax_rate',
            displayName: 'Tax Rate'
        },
        {
            id: 'p_sub_total',
            displayName: 'Tax Value'
        },
        {
            id: 'p_igst',
            displayName: 'IGST'
        },
        {
            id: '',
            displayName: 'CGST'
        },
        {
            id: '',
            displayName: 'SGST'
        },
        {
            id: 'p_sub_total',
            displayName: 'Invoice Value'
        },
        {
            id: 'payment_method',
            displayName: 'Payment Method'
        }, {
            id: 'created_date',
            displayName: 'Order Date'
        },
        {
            id: 'cod_receive_amt',
            displayName: 'COD Amount'
        },
        {
            id: 'prepaid_received_amt',
            displayName: 'Prepaid Amount'
        },
    ];
    const exportOrder = order;
    let handleFilterInputs = e => {
        setFilterValue({
            ...filtervalues,
            [e.target.name]: e.target.value,
        });
    };
    const handleOrderFilter = async (event) => {
        setIsloading(true);
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
        }
        else {
            try {
                fetchFilterOrderDataAPI('account-get-filter-order-data', filtervalues).then((res) => {
                    if (res.data.status === 1) {
                        setIsloading(false);
                        setOrder(...[res.data.order]);
                    }
                    else {
                        setIsloading(false);
                        setOrder("");
                    }
                })
            }
            catch (error) {
                toast.error("Something is worng!!");
            }
        }
        setFilterValidated(true);
    };
    const refreshOrderButton = () => {
        getAllCreatedOrder()
    }
    return (
        <div className="misDashboard">
            <Helmet>
                <title>SATKARTAR:ACCOUNT::ALL ORDERS</title>
            </Helmet>
            <ContentWrapper>
                <div className="page-wrapper breadcum">
                    <div className="page-breadcrumb d-none d-sm-flex align-items-center justify-content-between">
                        <div className="">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 p-0">
                                    <li className="breadcrumb-item">
                                        <Link to="/dashboard">Home</Link>
                                    </li>
                                    <li className="breadcrumb-item" aria-current="page">All ORDERS</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div className="customFilter">
                        <div className="card">
                            <div className="filterBody">
                                <div className="row">
                                    <div className="col-12 col-lg-12">
                                        <Form noValidate validated={filtervalidated} onSubmit={handleOrderFilter} method="POST">
                                            <Row className="mb-3">
                                                <Form.Group as={Col} md="3" controlId="validationCustom01">
                                                    <Form.Label>Order ID</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Search By Order ID"
                                                        name="f_order_id"
                                                        onChange={handleFilterInputs}
                                                    />
                                                </Form.Group>
                                                <Form.Group as={Col} md="3" controlId="validationCustom01">
                                                    <Form.Label>Start Date</Form.Label>
                                                    <Form.Control
                                                        type="date"
                                                        placeholder="Enter the Mobile Number"
                                                        name="f_order_sdate"
                                                        onChange={handleFilterInputs}
                                                    />
                                                </Form.Group>
                                                <Form.Group as={Col} md="3" controlId="validationCustom01">
                                                    <Form.Label>End Date</Form.Label>
                                                    <Form.Control
                                                        type="date"
                                                        placeholder="Enter the Mobile Number"
                                                        name="f_order_edate"
                                                        onChange={handleFilterInputs}
                                                    />
                                                </Form.Group>
                                                <Form.Group as={Col} md="3" className="btnosection">
                                                    <Button type="submit" className="btn btn-sm btn-primary">Submit</Button>

                                                    <span style={{ marginLeft: "10px" }} onClick={refreshOrderButton} className="btn btn-sm btn-secondary"><FiRefreshCcw /> Refresh</span>

                                                    <CsvDownloader className="btn btn-info btn-sm" filename="All Orders"
                                                        extension=".csv"
                                                        datas={exportOrder}
                                                        columns={columns}
                                                    >
                                                        <CiExport className="ofiltersvg " />
                                                        <span> Export</span>
                                                    </CsvDownloader>

                                                </Form.Group>
                                            </Row>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card tablecard">
                        <div className="table-responsive">
                            <Table className="table-sm" responsive="lg" striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Order No.</th>
                                        <th>Delivery Date</th>
                                        <th>GST No.</th>
                                        <th>Buyer Name</th>
                                        <th>Product Name</th>
                                        <th>QTY</th>
                                        <th>Sell Price</th>
                                        <th>Buyer State</th>
                                        <th>HSN Code</th>
                                        <th>Tax Rate</th>
                                        <th>Tax Value</th>
                                        <th>IGST</th>
                                        <th>CGST</th>
                                        <th>SGST</th>
                                        <th>Invoice Value</th>
                                        <th>Payment Method</th>
                                        <th>Order Date</th>
                                        <th>COD Amount</th>
                                        <th>Prepaid Amount</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {isLoading ?
                                        <>
                                            <tr>
                                                {Array.from({ length: 21 }).map((_, index) => (
                                                    <td key={index}><Skeleton animation="wave" variant="text" /></td>
                                                ))}
                                            </tr>
                                            <tr>
                                                {Array.from({ length: 21 }).map((_, index) => (
                                                    <td key={index}><Skeleton animation="wave" variant="text" /></td>
                                                ))}
                                            </tr>
                                            <tr>
                                                {Array.from({ length: 21 }).map((_, index) => (
                                                    <td key={index}><Skeleton animation="wave" variant="text" /></td>
                                                ))}
                                            </tr>
                                            <tr>
                                                {Array.from({ length: 21 }).map((_, index) => (
                                                    <td key={index}><Skeleton animation="wave" variant="text" /></td>
                                                ))}
                                            </tr>
                                            <tr>
                                                {Array.from({ length: 21 }).map((_, index) => (
                                                    <td key={index}><Skeleton animation="wave" variant="text" /></td>
                                                ))}
                                            </tr>
                                            <tr>
                                                {Array.from({ length: 21 }).map((_, index) => (
                                                    <td key={index}><Skeleton animation="wave" variant="text" /></td>
                                                ))}
                                            </tr>
                                            <tr>
                                                {Array.from({ length: 21 }).map((_, index) => (
                                                    <td key={index}><Skeleton animation="wave" variant="text" /></td>
                                                ))}
                                            </tr>
                                            <tr>
                                                {Array.from({ length: 21 }).map((_, index) => (
                                                    <td key={index}><Skeleton animation="wave" variant="text" /></td>
                                                ))}
                                            </tr>
                                            <tr>
                                                {Array.from({ length: 21 }).map((_, index) => (
                                                    <td key={index}><Skeleton animation="wave" variant="text" /></td>
                                                ))}
                                            </tr>
                                            <tr>
                                                {Array.from({ length: 21 }).map((_, index) => (
                                                    <td key={index}><Skeleton animation="wave" variant="text" /></td>
                                                ))}
                                            </tr>
                                        </>
                                        :
                                        records ?
                                            records?.map((records, index) => (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td><Badge bg="success">{records?.p_order_number}</Badge></td>
                                                    <td><Badge bg="success">{moment(records?.delivery_date).format('Do MMMM YYYY')}</Badge ></td>
                                                    <td>{records?.order_gst_no}</td>
                                                    <td>{records?.order_name}</td>
                                                    <td>{records?.p_name}</td>
                                                    <td><Badge bg="danger">{records?.p_qty}</Badge></td>
                                                    <td><Badge bg="warning">{numberFormat(records?.p_selling_price)}</Badge></td>
                                                    <td>{records?.order_state}</td>
                                                    <td>{records?.p_hsn_code}</td>
                                                    <td><Badge bg="info">{numberFormat(records?.p_tax_rate)}</Badge></td>
                                                    <td><Badge bg="info">{numberFormat(records?.p_sub_total)}</Badge></td>
                                                    <td>{records?.p_igst}</td>
                                                    <td>0.00</td>
                                                    <td>0.00</td>
                                                    <td><Badge bg="info">{numberFormat(records?.p_sub_total)}</Badge></td>
                                                    <td>{records?.payment_method}</td>
                                                    <td><Badge bg="success">{moment(records?.created_date).format('Do MMMM YYYY')}</Badge ></td>
                                                    <td><Badge bg="info">{numberFormat(records?.cod_receive_amt)}</Badge></td>
                                                    <td><Badge bg="info">{numberFormat(records?.prepaid_received_amt)}</Badge></td>
                                                    <td><Link to={`/invoice/${records.order_number}`} className="badge badge-success invdisplay"><FaRegEye style={{ fontSize: "20px" }} /></Link></td>
                                                </tr>
                                            ))
                                            :
                                            <tr>
                                                <td colSpan={25} className="text-center">Data not founds..</td>
                                            </tr>
                                    }
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th>#</th>
                                        <th>Order No.</th>
                                        <th>Delivery Date</th>
                                        <th>GST No.</th>
                                        <th>Buyer Name</th>
                                        <th>Product Name</th>
                                        <th>QTY</th>
                                        <th>Sell Price</th>
                                        <th>Buyer State</th>
                                        <th>HSN Code</th>
                                        <th>Tax Rate</th>
                                        <th>Tax Value</th>
                                        <th>IGST</th>
                                        <th>CGST</th>
                                        <th>SGST</th>
                                        <th>Invoice Value</th>
                                        <th>Payment Method</th>
                                        <th>Order Date</th>
                                        <th>COD Amount</th>
                                        <th>Prepaid Amount</th>
                                        <th>Action</th>
                                    </tr>
                                </tfoot>
                            </Table>
                            <nav>
                                <ul className="pagination justify-content-end round-pagination">
                                    <li className="page-item">
                                        <a href="#" className="page-link" onClick={perPage}>Previous</a>
                                    </li>
                                    {
                                        numbers.map((n, i) => (
                                            <li className={`page-item ${currentPage === n ? 'active' : ''}`} key={i}>
                                                <a href="#" className="page-link" onClick={() => changePage(n)}>{n}</a>
                                            </li>
                                        ))
                                    }
                                    <li className="page-item">
                                        <a href="#" className="page-link" onClick={nextPage}>Next</a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        </div>
    )
    function perPage() {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1)
        }
    }
    function changePage(id) {
        setCurrentPage(id)
    }
    function nextPage() {
        if (currentPage !== npage) {
            setCurrentPage(currentPage + 1)
        }
    }
}

export default AllOrder