import React, { useState } from 'react'
import favicon from "../assets/favicon.png"
import { Helmet } from 'react-helmet'
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { reSendMobileOTP, verifyMobileOTPAPI } from '../utils/api';
import loadingif from "../assets/loading-gif.gif";
import { Col, Form, Row } from 'react-bootstrap';
import { FaRegHandPointRight } from 'react-icons/fa';

const MobileOTP = () => {
    const mobile = localStorage.getItem("accountmobile");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [values, setInputValue] = useState("");
    const [validated, setValidated] = useState(false);

    const handleOTPSubmit = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
        }
        else {
            setLoading(true);
            const mobiledata = {
                mobile: mobile,
                mobileotp: values?.account_otp,
            };
            verifyMobileOTPAPI("account-verify-mobile-otp", mobiledata).then((res) => {
                if (res.data.status === 1) {
                    localStorage.setItem("accountdata", res.data.data);
                    navigate("/dashboard");
                    toast.success(res.data.msg);
                    setLoading(false);
                }
                else {
                    setLoading(false);
                    toast.error(res.data.msg);
                }
            })
        }
        setValidated(true);
    }
    const resendMobileOTP = () => {
        if (mobile.length === 10) {
            reSendMobileOTP("account-resend-mobile-otp", mobile).then((res) => {
                if (res.data.status === 1) {
                    toast.success("Mobile OTP has been send!!")
                }
                else {
                    toast.error("Check you connection!!")
                }
            })
        }
        else {
            toast.error("Invalid Mobile Number!!");
        }
    }
    const handleInputs = e => {
        setInputValue({
            ...values,
            [e.target.name]: e.target.value,
        });
    };
    return (
        <div className="homePage">
            <Helmet>
                <title>SATKARTAR:MOBILE OTP</title>
            </Helmet>
            <div className="container h-100">
                <div className="d-flex justify-content-center h-100">
                    <div className="user_card">
                        <div className="d-flex justify-content-center">
                            <div className="brand_logo_container">
                                <img src={favicon} className="brand_logo" alt="Logo" />
                            </div>
                            <h2 className="loginnames">ACCOUNT LOGIN</h2>
                        </div>
                        <div className="d-flex justify-content-center form_container">
                            <Form noValidate validated={validated} onSubmit={handleOTPSubmit}>
                                <Row>
                                    <Form.Group as={Col} md="12" controlId="validationCustom01">
                                        <Form.Label>Enter Mobile OTP</Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder="Enter Mobile OTP"
                                            onChange={handleInputs}
                                            name="account_otp"
                                            pattern="[0-9]*"
                                            maxLength={4}
                                            minLength={4}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            <FaRegHandPointRight /> Please provide a valid Mobile OTP
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <div className="d-flex justify-content-end login_container">
                                        <a href="javascript:void(0)" className="reSendMobileOTP" onClick={resendMobileOTP}>Re-Send Mobile OTP</a>
                                    </div>
                                    <div className="d-flex justify-content-center mt-3 login_container">
                                        {loading ?
                                            <button disabled={true} type="submit" className="btn login_btn">
                                                <img src={loadingif} alt={loadingif} style={{ width: "7%", marginTop: "-3px" }} /> VERIFY OTP...
                                            </button>
                                            :
                                            <button type="submit" className="btn login_btn">LOGIN</button>
                                        }
                                    </div>
                                </Row>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MobileOTP
